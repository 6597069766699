var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-body consultant-calendar-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-3"
  }, [_c('div', {
    staticClass: "img-consultant-pictrue"
  }, [_c('img', {
    attrs: {
      "src": _vm.consultantDetail.picture,
      "alt": _vm.consultantDetail.name,
      "title": _vm.consultantDetail.name
    }
  })])]), _c('div', {
    staticClass: "col-9 title-text"
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.consultantDetail.name))]), _c('b-button', {
    staticClass: "request-button",
    on: {
      "click": function ($event) {}
    }
  }, [_vm._v("+")]), _c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v("點擊 + 進行顧問未開放時段之申請，需經顧問同意後始完成訂課")])], 1)]), _c('full-calendar', {
    ref: "calendar",
    staticClass: "consultant-calendar",
    attrs: {
      "events": _vm.events,
      "header": _vm.header,
      "default-view": "month",
      "selectable": false,
      "config": _vm.config
    },
    on: {
      "event-selected": _vm.onClickEvent,
      "day-click": _vm.onDayClick
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
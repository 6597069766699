<template lang="pug">
  .wuwow-card
    .wuwow-card-body.consultant-calendar-body
      .row
        .col-3
          .img-consultant-pictrue
            img(:src="consultantDetail.picture" :alt="consultantDetail.name" :title="consultantDetail.name")
        .col-9.title-text
          h3.mb-4 {{ consultantDetail.name }}
          b-button.request-button(@click='') +
          b-alert.text-center(variant="warning" show) 點擊 + 進行顧問未開放時段之申請，需經顧問同意後始完成訂課
      full-calendar.consultant-calendar(ref="calendar" :events="events" :header="header" default-view="month" :selectable="false" :config="config" @event-selected="onClickEvent" @day-click="onDayClick")
</template>

<script type="text/javascript">
import { consultantDetailLang } from './lang';
import lioshutanApi from '@/api';
import { FullCalendar } from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.css';

export default {
  name: 'ConsultantScheduleView',

  components: {
    FullCalendar,
  },

  i18n: consultantDetailLang,

  data() {
    return {
      consultantDetail: {},
      consultantTimeslots: {},
    };
  },

  computed: {},

  watch: {},

  created() {
    this.setConsultantDetail();
    this.setConsultantTimeslots();
  },

  methods: {
    async setConsultantDetail(){
      const response = await lioshutanApi.personnel.queryConsultantDetail(this.$route.params.id);
      this.consultantDetail = response.data.data.hb_profile;
    },
    async setConsultantTimeslots(){
      const params = {
        ui: 7,
        st: 2022060906,
        et: 2022070931,
      };
      const response = await lioshutanApi.personnel.queryConsultantTimeslots(params);
      this.consultantProfile = response.data.data.hb_profile;
    },
  },
};
</script>
<style lang="scss">
.consultant-calendar-body {
  position: relative;
}
.title-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .request-button {
    // position: absolute;
    // top: 24px;
    // right: 180px;
    padding: 0px;
    width: 45px;
    font-size: 18px;
    font-weight: 700;
    background-color: #F4F4F4;
    border-color: #DDDDDD;
  }
}
.img-consultant-pictrue {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  img {
    max-width: 100%;
  }
}
.fc {
  margin-top: 25px;
}
.fc-toolbar.fc-header-toolbar{
  width: 60%;
  position: absolute;
  top: 53px;
  right: 30px;
}
.alert {
  margin-bottom: 0;
}
// .consultant-pictrue {
//   align-items: center;
//   border-radius: 50%;
//   display: flex;
//   height: 140px;
//   overflow: hidden;
//   position: relative;
//   width: 140px;

//   .img-consultant-pictrue {
//     max-width: 100%;
//     position: absolute;
//   }
// }
.consultant-calendar {
  .fc-right {
    display: none;
  }
  .fc-left{
    float: right;
  }
}
</style>
